<template>
  <div class="side">
    <div class="side_top">
      <p>地址：广州市天河区东圃镇天河广场天威阁24B</p>
      <p>TEL:020-82513199</p>
      <p>微信：yaheen_com</p>
    </div>
    <div class="side_box">
      <a
        href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action;jsessionid=HJuNyW3aSC56qd0R5C7fg7xTddLpU8eY_rimLzRAnsCZQ5-3VTqF!-1797433545#/Integrated/recordQuery"
      >
        CopyRight© 广州市雅恒信息科技有限公司 粤ICP备14013464号</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.side {
  width: 100%;
  height: 88px;
  background-color: #18181a;
  .side_top {
    display: flex;
    justify-content: center;
    color: #999999;
    font-size: 12px;
    line-height: 50px;
    p {
      padding-left: 20px;
      padding-top: 5px;
    }
  }
  .side_box {
    display: flex;
    justify-content: center;
    color: #666666;
    font-size: 12px;
  }
  .side_box a {
    color: #666;
    font-size: 12px;
    text-decoration: none;
  }
}
</style>
