<template>
  <div class="About" id="about">
    <div class="a_top">
      <div class="a_title">
        <div>关于我们</div>
        <div>WHO WE ARE</div>
      </div>
      <div class="a_nav">
        <ul>
          <li
            v-if="we_status != 0"
            style="background-color: #1b69e1"
            @click="on_we_status(0)"
          >
            简介
          </li>
          <li v-else style="background-color: #6fcdff" @click="on_we_status(0)">简介</li>
          <li
            v-if="we_status != 1"
            style="background-color: #1b69e1"
            @click="on_we_status(1)"
          >
            动态
          </li>
          <li v-else style="background-color: #6fcdff" @click="on_we_status(1)">动态</li>
          <li
            v-if="we_status != 2"
            style="background-color: #1b69e1"
            @click="on_we_status(2)"
          >
            历程
          </li>
          <li v-else style="background-color: #6fcdff" @click="on_we_status(2)">历程</li>
        </ul>
      </div>
    </div>
    <div class="a_map" v-show="we_status == 0">
      <div class="map_main">
        <div class="map_img">
          <div class="img_div">
          <!-- <img src="../assets/1.jpg" alt="" /> -->
          <v-webgltest></v-webgltest>
          </div>
        </div>
        <div class="map_p">
          <p>
            <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广州市雅恒信息科技有限公司</b
            >(Yaheen.com、Cngridding.com）2013年在广州注册成立。成立以来，从承接政府单位、学校、企业的工程项目，到研发出自己的产品，再到提炼出属于雅恒的核心技术，再到把技术应用到专业领域。公司一直秉承与时俱进、以技术为核心的理念，把最优质的用户体验、最新的互联网模式输送到每个系统和平台中去。公司目前由广州市天河区的研发中心、番禺理工计算机专业科研中心、外聘专家团队，以及全国各地的项目投资公司和业务公司组成。
          </p>
        </div>
      </div>
    </div>
    <div style="width: 1500px; margin: auto" v-show="we_status == 1" class="dynamic">
      <div class="dynamic swiper-container" id="aboutsSwiper" v-if="DEBUG_SHOWABOUT">
        <div class="dy_main swiper-wrapper" id="aboutDyMain">
          <div
            class="d_mian swiper-slide"
            v-for="item in list"
            :key="item.id"
            @click="getInfo(item.id)"
          >
            <ul>
              <li>
                <img :src="item.thumbimage" alt="" />
              </li>
              <li>
                <p>{{ proxy.$time.getYmdHis(item.createtime * 1000) }}</p>
              </li>
              <li>
                <h2>
                  {{ item.title }}
                </h2>
              </li>
              <li>
                <p>
                  {{ item.description }}
                </p>
              </li>
              <li><img src="../assets/222.webp" alt="" /></li>
            </ul>
          </div>
        </div>
      </div>
      <swiper
        :slides-per-view="4"
        :space-between="0"
        :loop="true"
        :observer="true"
        :observe-parents="true"
        :observe-slide-children="true"
        :autoplay="autoplay"
        @swiper="onSwiper"
        class="dy_main"
      >
        <swiper-slide v-for="item in list" :key="item.id" class="d_mian">
          <ul @click="getInfo(item.id)">
            <li>
              <img :src="item.thumbimage" alt="" />
            </li>
            <li>
              <p>{{ proxy.$time.getYmdHis(item.createtime * 1000) }}</p>
            </li>
            <li>
              <h2>
                {{ item.title }}
              </h2>
            </li>
            <li>
              <p>
                {{ item.description }}
              </p>
            </li>
            <li><img src="../assets/222.webp" alt="" /></li>
          </ul>
        </swiper-slide>
      </swiper>
    </div>

    <div class="course" v-show="we_status == 2">
      <div class="co_top">
        <div class="top_main">
          <div class="qiu">
            <div class="yuan">
              <div class="yuan_in">
                <p>2012</p>
              </div>
            </div>
            <div class="shu"></div>
            <div class="dian"></div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>2013开发小组配备完成，雅恒科技在广州成立，打出“it定制解决方案口号”.</p>
            </div>
          </div>
          <div class="qiu">
            <div class="yuan">
              <div class="yuan_in" style="background-color: #93d93b">
                <p>2014</p>
              </div>
            </div>
            <div class="shu"></div>
            <div class="dian"></div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>技术和效率受到早期客户认可，其中最具代表的是为宝洁3天开发4个app.</p>
            </div>
          </div>
          <div class="qiu">
            <div class="yuan">
              <div class="yuan_in" style="background-color: #8d8d8f">
                <p style="padding-top: 15px; line-height: 20px">2015-2016</p>
              </div>
            </div>
            <div class="shu"></div>
            <div class="dian"></div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>
                提出技术即资本，先后投资多个领域公司，如：防伪溯源、互联网工商、大健康、数字政府、区块链等
              </p>
            </div>
          </div>
          <div class="qiu">
            <div class="yuan">
              <div class="yuan_in" style="background-color: #f1ca4b">
                <p>2019</p>
              </div>
            </div>
            <div class="shu"></div>
            <div class="dian"></div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>洗净铅华、展望未来！！</p>
            </div>
          </div>
                    <div class="qiu">
            <div class="yuan">
              <div class="yuan_in" style="background-color: #ff9800">
                <p>2021</p>
              </div>
            </div>
            <div class="shu"></div>
            <div class="dian"></div>
          </div>
        </div>
      </div>
      <div class="co_in">
        <div class="in_border">
          <div class="yuan">
            <div class="dian"></div>
          </div>
          <div class="san"></div>
        </div>
      </div>
      <div class="co_box">
        <div class="box_main">
          <div class="zi">
            <div class="zi_main">
              <p>一群怀有激情与梦想的小伙，共同组织了一个软件开发小组.</p>
            </div>
          </div>
          <div class="qiu">
            <div class="dian"></div>
            <div class="shu"></div>

            <div class="yuan">
              <div class="yuan_in" style="background-color: #32c0ee">
                <p>2013</p>
              </div>
            </div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>
                与高校展开合作，先后为学校、教育局、教育厅、教育研究院研发多个系统，为往后人才供给打下基础..
              </p>
            </div>
          </div>
          <div class="qiu">
            <div class="dian"></div>
            <div class="shu"></div>
            <div class="yuan">
              <div class="yuan_in" style="background-color: #3776f2">
                <p>2015</p>
              </div>
            </div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>
                深入研究多种电商模式、运营模式，其中“惠民谷”、“温碧泉-不做黏黏女友”、“码上销”等最具代表性
              </p>
            </div>
          </div>
          <div class="qiu">
            <div class="dian"></div>
            <div class="shu"></div>
            <div class="yuan">
              <div class="yuan_in" style="background-color: #f94540">
                <p style="padding-top: 15px; line-height: 20px">2017-2018</p>
              </div>
            </div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>技术沉淀、打磨产品！！</p>
            </div>
          </div>
          <div class="qiu">
            <div class="dian"></div>
            <div class="shu"></div>
            <div class="yuan">
              <div class="yuan_in" style="background-color: #2d7deb">
                <p>2020</p>
              </div>
            </div>
          </div>
          <div class="zi">
            <div class="zi_main">
              <p>深耕茶叶产业互联⽹</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 弹出层-文章详情 -->
  <v-article-info
    v-model:title="title"
    v-model:createtime="createtime"
    v-model:html="html"
    v-model:visible="visible"
  />
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
// import webgltest from "../view/WebglTest.vue";
import webgltest from "@/components/webgl/yaheen.vue";
// import Swiper from "swiper";
// import "swiper/swiper-bundle.min.css";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

SwiperCore.use([Autoplay]);
//创建vue示例
export default defineComponent({
  name: "AboutusC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    let list: any = [];
    let swiper: any = "";
    let autoplay: any = {
      delay: 1500,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    };
    return {
      we_status: 0,
      page: 1,
      displayNum: 1000,

      getStatus: false,
      list,
      pageNum: 1,
      total: 0,

      swiper,
      transformX: -375,
      slidesPerView: 4,
      loopedSlides: 4,

      autoplay,

      title: "",
      createtime: 0,
      html: "",

      DEBUG_SHOWABOUT: false, //原有显示关于我们
    };
  },
  watch: {
    visible(newVisible: boolean, oldVisible: boolean) {
      if (newVisible) {
        this.autoplay = oldVisible;
        this.swiper.disable();
      } else {
        this.swiper.enable();
        this.autoplay = {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        };
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    if (this.DEBUG_SHOWABOUT) {
      var swiper = new Swiper("#aboutsSwiper", {
        width: 1500,
        slidesPerView: this.slidesPerView,
        loopedSlides: this.loopedSlides,
        loop: false,
        on: {
          touchMove: (swiper: any) => {
            var TR = (this.page * this.displayNum - this.loopedSlides) * this.transformX;
            var translate = swiper.translate;
            if (translate < TR) {
              this.nextPage();
            }
          },
          touchEnd: () => {
            this.swiper.updateSlides();
          },
        },
      });
      this.swiper = swiper;
    }
  },
  methods: {
    on_we_status(sun: number) {
      this.we_status = sun;
      if (this.DEBUG_SHOWABOUT) {
        if (sun == 1) {
          this.swiper.updateSlides();
        }
      }
    },
    /**
     * swiper加载完成后的回调函数
     */
    onSwiper(swiper: any) {
      this.swiper = swiper;
    },
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.getStatus) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        } else {
          console.log("no next page~");
        }
      }
    },
    /**
     * 获取关于我们列表
     */
    getList() {
      this.getStatus = true;
      this.proxy.$request.aboutsList({
        data: { page: this.page, displayNum: this.displayNum },
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            // this.swiper.updateSlides();
            this.getStatus = false;
          }
        },
        error: (error: any) => {
          this.getStatus = false;
          console.log(error);
        },
      });
    },
    /**
     * 关闭窗口
     */
    closeInfo() {
      this.visible = false;
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.aboutsInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(result.data.createtime * 1000);
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    "v-article-info": articleInfo,
    "v-webgltest": webgltest,
  },
});
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.About {
  width: 100%;
  height: 985px;
  // background: url("../assets/wmbj.webp") no-repeat center;
  // background-size: 100% 100%;
  background-color: #ffffff;
  // filter: alpla(opacity=50);
  // -moz-opacity: 0.5;
  // opacity: 0.3;
}
.a_top {
  width: 1450px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a_top .a_title {
  width: 50%;
  height: 200px;
  display: flex;
  align-items: center;
}
.a_top .a_title div:nth-child(1) {
  width: 180px;
  height: 100px;
  font-size: 40px;
  color: #090909;
  // opacity: 1;
}
.a_top .a_title div:nth-child(2) {
  width: 300px;
  height: 100px;
  font-size: 36px;
  color: #f0f0f0;
  text-align: left;
  line-height: 70px;
  font-weight: 100;
}
.a_top .a_nav {
  width: 50%;
  height: 200px;
  display: flex;
  flex-direction: row-reverse;
}
.a_top .a_nav ul {
  width: 375px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  li:hover {
    background-color: #6fcdff;
  }
}
.a_top .a_nav ul li {
  cursor: pointer;
  width: 110px;
  height: 45px;
  background-color: #1b69e1;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  line-height: 45px;
}
.a_map {
  width: 100%;
  height: 685px;
  margin: 0px auto 0;
  padding-top: 80px;
  background: url("../assets/wmbj.webp") no-repeat center;
  background-size: 100% 100%;
  background-color: #ffffff;
}
.a_map .map_main {
  width: 100%;
  height: 500px;
  display: flex;
}
.a_map .map_main .map_img {
  width: 50%;
  //   width: 573px;
  // height: 367px;
  height: auto;
  // padding-left: 45px;
  padding-left: 45px;
}
.a_map .map_main .map_img .img_div {
  width: 573px;
  height: 367px;
  // border-radius: 20px;
}
.a_map .map_main .map_p {
  width: 50%;
}
.a_map .map_main .map_p p {
  width: 640px;
  padding-top: 10px;
  font-size: 20px;
  text-align: left;
  line-height: 45px;
}
.course {
  width: 1450px;
  height: auto;
  margin: 80px auto 0;
  .co_top {
    width: 1450px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10px;
    .top_main {
      width: 1400px;
      display: flex;
      justify-content: space-between;
      .qiu {
        width: 100px;
        .yuan {
          width: 88px;
          height: 88px;
          background-color: #ffffff;
          box-shadow: 0px 0px 10px #a8a8a8;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          .yuan_in {
            width: 66px;
            height: 66px;
            background-color: #f17728;
            border-radius: 50%;
            p {
              height: 66px;
              color: #ffffff;
              line-height: 66px;
              text-align: center;
            }
          }
        }

        .shu {
          width: 2px;
          height: 100px;
          background-color: #a8a8a8;
          margin: auto;
        }
        .dian {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #a8a8a8;
          margin: auto;
        }
      }
    }
    .zi {
      width: 240px;
      margin-top: 70px;
      .zi_main {
        width: 170px;
        margin: auto;
        p {
          text-align: center;
        }
      }
    }
  }
  .co_in {
    width: 1450px;
    .in_border {
      width: 1450px;
      height: 2px;
      background-color: #a8a8a8;
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin: auto;
      .yuan {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        border: solid #a8a8a8 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -20px;
        .dian {
          width: 20px;
          height: 20px;
          border-radius: 50px;
          background-color: #a8a8a8;
        }
      }
      .san {
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-top-color: transparent;
        /* border-bottom: red; */
        border-left-color: #a8a8a8;
        border-right-color: transparent;
        /* margin-left: 58px; */
        position: relative;
        left: 20px;
      }
    }
  }
  .co_box {
    width: 1400px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -17px;
    .box_main {
      width: 1450px;
      display: flex;
      justify-content: space-between;
      .qiu {
        width: 100px;
        .yuan {
          width: 88px;
          height: 88px;
          background-color: #ffffff;
          box-shadow: 0px 0px 10px #a8a8a8;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          .yuan_in {
            width: 66px;
            height: 66px;
            background-color: #f17728;
            border-radius: 50%;
            p {
              height: 66px;
              color: #ffffff;
              line-height: 66px;
              text-align: center;
            }
          }
        }
        .shu {
          width: 2px;
          height: 100px;
          background-color: #a8a8a8;
          margin: auto;
        }
        .dian {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #a8a8a8;
          margin: auto;
          position: relative;
          top: 5px;
        }
      }
    }
    .zi {
      width: 240px;
      margin-top: 50px;
      .zi_main {
        width: 170px;
        margin: auto;
        p {
          text-align: center;
        }
      }
    }
  }
}
.dynamic {
  // width: 80%;
  height: 650px;
  position: relative;
  overflow: hidden;
  // height: auto;
  margin: 00px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dy_main {
    margin: 80px auto 0;
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    // position: absolute;
    // white-space: nowrap;
    .d_mian {
      // width: 288px;
      // width: 380.75px;
      height: 500px;
      // margin-right: 50px;
      ul {
        width: 288px;
        height: 500px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
      }
      li {
        margin: 0;
      }
      li:nth-child(1) {
        width: 288px;
        height: 210px;
        img {
          width: 288px;
          height: 210px;
          // border-radius: 8px;
        }
      }
      li:nth-child(2) {
        border: solid 1px #c9c9c9;
        border-radius: 5px;
        margin: 20px auto;

        p {
          width: 288px;
          height: 45px;
          color: #c9c9c9;
          text-align: center;
          line-height: 45px;
        }
      }
      li:nth-child(3) {
        width: 288px;
        height: 20px;
        h2 {
          width: 288px;
          text-align: left;
          font-size: 18px;
          color: #090909;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      li:nth-child(4) {
        margin: 20px auto;
        width: 288px;
        height: 100px;
        p {
          width: 288px;
          height: 100px;
          line-height: 25px;
          text-align: left;
          font-size: 16px;
          color: #767676;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: pre-line;
        }
      }
      li:nth-child(5) {
        width: 40px;
        height: 40px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
// .poper{width: 100%;height: 100%;margin: auto;
.popup {
  width: 1920px;
  height: 4020px;
  z-index: 999999;
  // background-color: #0909098f;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: auto;
  .pop {
    width: 1080px;
    height: 800px;
    border-radius: 20px;
    margin: 80px auto;
    background-color: #f0f0f0;
    border: 2px solid #949494;
    z-index: 999999;
    .pop_title {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #999999;
      border-radius: 20px 20px 0 0;
      div:nth-child(2) {
        width: 500px;
      }
      div {
        width: 50px;

        h2 {
          width: 500px;
          text-align: center;
          line-height: 55px;
          color: #090909;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
    .pop_main {
      .html {
        width: 95%;
        height: 90%;
      }
    }
  }
}
// }
</style>
