<template>
  <div class="fervour" id="fervour">
    <div class="a_top">
      <div class="a_title">
        <div></div>
        <div>激情</div>
        <div>YAHEEN LIVE</div>
      </div>
    </div>
    <div class="a_bootm">
      <swiper
        :slides-per-view="1.2"
        navigation
        :space-between="0"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          class="main"
          v-for="(item, index) in list"
          :key="item.id"
          :id="`swiper-slide-id-${index}`"
        >
          <template v-if="index == listIndex">
            <div class="main-description">
              <!-- 标题 -->
              <div class="title">{{ item.title }}</div>
              <!-- 发表时间 -->
              <!-- <div class="time">
            {{ proxy.$time.getYmdHis(item.createtime * 1000) }}
          </div> -->
              <!-- 详情 -->
              <!-- <div @click="getInfo(item.id)" class="info">详情</div> -->
              <!-- 简介 -->
              <div class="description">
                {{ item.description }}
              </div>
            </div>
            <div class="main-show" v-html="item.thumbimage_content"></div>
          </template>
          <template v-else>
            <div class="main-show">
              <img :src="item.thumbimage" class="next-img" />
            </div>
          </template>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <!-- 弹出层-文章详情 -->
  <v-article-info
    v-model:title="title"
    v-model:createtime="createtime"
    v-model:html="html"
    v-model:visible="visible"
  />
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);
//创建vue示例
export default defineComponent({
  name: "FervourC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    let list: any = [];
    let swiper: any = "";
    let listIndex: any = 0;
    return {
      page: 1,
      displayNum: 3,
      getStatus: false,
      list: list,
      listIndex: listIndex,
      pageNum: 1,
      total: 0,

      swiper: swiper,
      transformX: -1416 + 1 / 3,
      loopedSlides: 1,

      title: "",
      createtime: 0,
      html: "",
    };
  },
  created() {
    // setTimeout(() => {
          this.listIndex = 0;
    this.getList();
    // }, 2000);
  },
  mounted() {},
  methods: {
    onSwiper(swiper: any) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.listIndex = (this.swiper.translate / this.transformX).toFixed();
      var TR =
        (this.page * this.displayNum - this.loopedSlides - 1) * this.transformX;
      this.changeLast();
      if (TR >= this.swiper.translate) {
        this.nextPage();
      }
    },
    /**
     * 改变最后一张图片的位置
     */
    changeLast() {
      let listNum = this.list.length;
      let lastId = `swiper-slide-id-${listNum - 1}`;
      let last = document.getElementById(lastId);
      // 判断当前展示数据是否为最后一条
      // 是 最后一个展示框向左偏移-270px
      // 是 最后一个展示框向不偏移
      if (this.listIndex == listNum - 1) {
        if (last) {
          if (this.listIndex == 0) {
            last.style.left = "-140px";
          } else {
            last.style.left = "-270px";
          }
        }
      } else {
        if (last) {
          last.style.left = "0px";
        }
      }
    },
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.getStatus) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        } else {
          console.log("no next page~");
        }
      }
    },
    /**
     * 获取活动列表
     */
    getList() {
      this.getStatus = true;
      this.proxy.$request.activityList({
        data: { page: this.page, displayNum: this.displayNum },
        async: true,
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            setTimeout(() => {
              this.changeLast();
            }, 500);
            this.getStatus = false;
          }
        },
        error: (error: any) => {
          this.getStatus = false;
          console.log(error);
        },
      });
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.activityInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(
              result.data.createtime * 1000
            );
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    "v-article-info": articleInfo,
  },
});
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.fervour {
  width: 100%;
  height: 985px;
  // background: url("../assets/wmbj.webp") no-repeat center;
  // background-size: 100% 100%;
  background-color: #1a1a1a;
  .a_bootm {
    width: 1700px;
    margin: 100px auto;
    margin-left: 10%;
    float: right;
    .left {
      width: 20%;
      z-index: 0;
      position: relative;
      overflow: hidden;
      list-style: none;
      .prevShow {
        width: 40%;
        position: relative;
        overflow: hidden;
        list-style: none;
        float: right;
      }
    }
    .swiper-wrapper {
      width: 1450px;
      .main {
        width: 1450px;
        z-index: 1;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        .main-description {
          position: absolute;
          left: 110px;
          right: auto;
          width: 250px;
          text-align: left;
          div {
            padding: 10px;
          }
          .title {
            color: #268ab9;
            font-size: 30px;
          }
          .time {
            font-size: 18px;
          }
          .info {
            cursor: pointer;
          }
          .description {
            color: #cdcdcd;
            font-size: 16px;
            max-height: 234px;
            overflow: hidden;
            padding-bottom: 0px;
            text-align: left;
            // text-indent: 2em;
          }
        }
        .main-show {
          width: 1550px;
          margin: 0 auto;
        }
      }
    }

    .right {
      width: 20%;
      z-index: 0;
      position: relative;
      overflow: hidden;
      list-style: none;
      .nextShow {
        width: 40%;
        position: relative;
        overflow: hidden;
        list-style: none;
        float: left;
      }
    }
  }
}
.a_top {
  width: 1450px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a_top .a_title {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.a_top .a_title div:nth-child(1) {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-top-color: rgb(255, 255, 255);
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.a_top .a_title div:nth-child(2) {
  width: 180px;
  height: 100px;
  font-size: 40px;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
  // opacity: 1;
}
.a_top .a_title div:nth-child(3) {
  width: 300px;
  height: 50px;
  font-size: 36px;
  color: #5b5b5b;
  text-align: center;
  line-height: 15px;
  font-weight: 100;
}
.a_top .a_nav {
  width: 50%;
  height: 200px;
  display: flex;
  flex-direction: row-reverse;
}
.a_top .a_nav ul {
  width: 375px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a_top .a_nav ul li {
  width: 110px;
  height: 45px;
  background-color: #1b69e1;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  line-height: 45px;
}

.swiper-container .swiper-button-hidden {
  opacity: 0;
}

.next-img {
  width: 768px;
  height: 432px;
  float: left;
  -webkit-filter: grayscale(1); /* Webkit */
  filter: gray; /* IE6-9 */
  filter: grayscale(1); /* W3C */
}
</style>

<style lang="scss">
@import url("../style/style.css");
/* 修改以下样式即可自适应改变 1920:1080 */
.ei-main {
  width: 768px;
  height: 432px;
  margin: 0 auto;
  .ei-image {
    width: 768px;
    height: 432px;
    img {
      width: 768px;
      height: 432px;
    }
  }
  .ei-content {
    width: 768px;
    height: 432px;
  }
}

/* 不用改变 */
.ei-image {
  position: absolute;
}

.ei-content {
  position: absolute;
}

/* 小点-start-old */
/* .show {
    border: 1px solid #F00;
    position: absolute;
    display: flex;
}

:root {
    --color: #0ebeff;
}

@keyframes rotate {
    100% {
        transform: translate(-50%, -50%) rotate(1turn);
    }
}

.show:hover .show-content {
    color: #fff;
    box-shadow: 0 0 5px var(--color), 0 0 25px var(--color);
    background-color: #03a9f4;
}

.show::after .show-content,
.show::before .show-content {
    transition: 0.3s;
    background: var(--color);
}

.show::before .show-content {
    content: "";
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 300%;
    background-color: #03a9f4;

}

.show::after .show-content {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 10px;
    background-color: #03a9f4;
}

.show-content {
    position: relative;
    z-index: 0;
    width: 30px;
    height: 30px;
    line-height: 80px;
    color: var(--color);
    font-size: 24px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
} */

/* 小点-end-old */

/* 鼠标展示点-start-new */
.show {
  position: absolute;
  display: flex;
}

:root {
  --color: #0ebeff;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

.show:hover .show-content {
  display: block;
}

.show-content {
  display: none;
  text-align: center;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  background-color: #97dfffb8; //外圈颜色
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  /*box-shadow: 0 0 0 30px rgba(255,80,0,.2)*/
}

.show-content::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #37c1ff; //内圈颜色
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/* 鼠标展示点-end-new */

.info-line-oblique {
  position: absolute;
  height: 50%;
}

.info-line-straight {
  position: absolute;
  height: 5px;
  border-top: 1px solid #03a9f4;
}

.ei-person-info {
  margin-left: 102%;
  width: 24%;
  height: 55%;
}

/* 头像 */
.ei-info-avatar {
  position: absolute;
  width: 24%;
  height: 55%;
}

.ei-info-info {
  position: absolute;
  width: 24%;
  height: 55%;
}

/* 名片链 */
.ei-info-bclinkimage {
  margin-top: 3.3%;
  text-align: center;
}
.ei-info-bclinkimage img {
  border-radius: 50%;
}

/* 姓名 */
.ei-info-name {
  position: relative;
  margin-top: 3.3%;
  text-align: center;
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
}

/* 职位 */
.ei-info-position {
  margin-top: 3.3%;
  text-align: center;
  color: #c0c4cc;
  position: relative;
  font-weight: bolder;
  font-size: 16px;
}

/* 详情 */
.ei-info-content {
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 5;
  width: 90%;
  height: 78%;
  margin: 0 auto;
  color: #cacaca;
  margin-top: 3.3%;
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  position: relative;
  text-indent: 2em;
}

/* 毛玻璃 */
.ei-info-info::before {
  position: absolute;
  content: "";
  background: #03a9f430;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(2px);
  z-index: 0;
}
</style>


<style lang="scss">
/* 上、下一页控件 */
.a_bootm {
  .swiper-container {
    .swiper-button-prev {
      left: 120px;
      right: auto;
      top: 85%;
      width: 60px;
      height: 55px;
      background: url("../assets/go-left.webp") no-repeat center;
      background-size: 100% 100%;
      &::after {
        content: "";
      }
    }
    .swiper-button-next {
      left: 220px;
      right: auto;
      top: 85%;
      width: 60px;
      height: 55px;
      background: url("../assets/go-right.webp") no-repeat center;
      background-size: 100% 100%;
      &::after {
        content: "";
      }
    }
  }
}
</style>