<template>
  <div class="container">
    <div class="nav_main">
      <div class="computer">
        <div class="banner_text">
          <p class="text1">雅恒科技</p>
          <!-- <p class="text2">让科技科学起来</p> -->
          <p class="text2"><img src="../assets/giftitle.gif" alt="" /></p>
        </div>
        <img src="../assets/banner-.gif" mode="" />
      </div>
      <div class="nav">
        <div class="nav_ui">
          <div class="nav_li">
            <img src="../assets/logo1.webp" mode="" />
          </div>
          <div class="nav_li">
            <a href="#about">我们</a>
          </div>
          <div class="nav_li">
            <a href="#fervour"> 激情 </a>
          </div>
          <div class="nav_li">
            <a href="#action"> 承诺 </a>
          </div>
          <div class="nav_li">
            <a href="#action"> 思考 </a>
          </div>
          <div class="nav_li">
            <a href="#action"> 行动 </a>
          </div>
          <div class="nav_li">
            <a href="#action"> 成果 </a>
          </div>
        </div>
      </div>
    </div>
    <v-aboutus></v-aboutus>
    <v-fervour></v-fervour>
    <v-action></v-action>
    <v-footer></v-footer>
    <div class="go_top">
      <div>
        <img src="../assets/ewm.webp" mode="" />
      </div>
      <div>
        <img @click="backtop()" src="../assets/db.webp" mode="" />
      </div>
      <div class="kuan" id="kuan" style="width: 220px; height: 210px">
        <img
          src="../assets/ewm.webp"
          style="width: 200px; height: 190px"
          mode=""
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import aboutus from "@/components/aboutus.vue";
import fervour from "@/components/fervour.vue";
import action from "@/components/action.vue";
import footer from "@/components/footer.vue";
import VueLazyload from 'vue-lazyload'

// import imgDefault from '@/assets/images/200.webp'
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "Index",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  created() {
    this.proxy.$comm.title("首页");
  },
  mounted(){
  },
  methods: {
    roll(sun: number) {
      var timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        document.documentElement.scrollTop = document.body.scrollTop = sun;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    /**
     * 回到顶部功能实现过程：
     * 1. 获取页面当前距离顶部的滚动距离（虽然IE不常用了，但还是需要考虑一下兼容性的）
     * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
     * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
     * 4. 最后记得在移动到顶部时，清除定时器
     */
    backtop() {
      var timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        // this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
  components: {
    "v-aboutus": aboutus,
    "v-fervour": fervour,
    "v-action": action,
    "v-footer": footer,
  },
});
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.go_top {
  width: 80px;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 150px;
  top: 700px;
  background-color: #c0c0c0;
  border-radius: 5px;
  z-index: 999999;
}

.go_top div {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.go_top div img {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 5px;
}

.go_top div:nth-child(1) {
  padding-top: 5px;
}
.go_top div:nth-child(1):hover ~ .kuan {
  display: block;
}

.go_top div:nth-child(2) {
  padding-bottom: 5px;
}

.kuan {
  width: 220px;
  height: 210px;
  background: url(../assets/kuan.webp);
  background-size: 100% 100%;
  position: fixed;
  right: 230px;
  top: 560px;
  display: none;
}

.kuan img {
  width: 200px;
  height: 190px;
  display: block;
  padding: 10px 0 0 4px;
}

.fullpage-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.introduction {
  width: 1300;
  height: 500px;
  margin: auto;
}

.ion {
  width: 1200;
  height: 500px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.ion_left {
  width: 501px;
  height: 410px;
  display: block;
}

.ion_left img {
  width: 501px;
  height: 410px;
  display: block;
}

.ion_rigth {
  width: 600px;
  height: 385px;
  display: flex;
  flex-direction: column;
}

.ion_rigth text:nth-child(1) {
  height: 75px;
  line-height: 75px;
  width: 100%;
  font-size: 30px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ion_rigth text:nth-child(2) {
  line-height: 40px;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.dynamic {
  width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
}
.dynamic_box {
  width: 2100px;
  height: 483px;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.dynamic_main {
  width: 25%;
  padding-right: 1%;
  height: 300px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  white-space: inherit;
}

.dynamic_time {
  width: 100%;
  height: 50px;
  margin: 20px auto 0;
}

.dynamic_time text {
  width: 80%;
  display: block;
  text-align: center;
  font-size: 20px;
  padding-left: 15px;
  height: 35px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  color: #ffffff;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dynamic_text {
  width: 90%;
  height: 160px;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.dynamic_text text:nth-child(1) {
  height: 35px;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  color: #ffffff;
  line-height: 35px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dynamic_text text:nth-child(2) {
  width: 90%;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  text-align: left;
  display: block;
  color: #ffffff;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.dynamic_enter {
  width: 100%;
  height: 55px;
}

.dynamic_enter img {
  width: 50px;
  height: 50px;
}

.swiper {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
.scroll-div-item {
  width: 100%;
  height: 100%;
  display: flex;
}
.scroll-div_H {
  width: 100%;
  height: 100%;
  display: flex;
}
.dynamic_box_1 {
  width: 100%;
  display: flex;
}
.bookshelf-content {
  /* white-space: nowrap; */
  width: 100%;
  height: 100%;
  padding: 20upx;
  margin: 0 auto;
  display: flex;
}
/*
nav
*/
.nav_main {
  width: 100%;
  height: 1075px;
  background: url(../assets/15.webp) no-repeat left;
  background-size: 100% 100%;
}

.nav {
  width: 100%;
  height: 120px;
  margin: 0px auto 0;
  position: absolute;
  top: 50px;
}

.nav .nav_ui {
  width: 1080px;
  height: 100px;
  margin: auto;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
}

.nav .nav_ui .nav_li {
  width: 105px;
  height: 40px;
}

.nav .nav_ui .nav_li a {
  display: block;
  width: 105px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  /* padding-top: 30px; */
}

.nav .nav_ui .nav_li a:hover {
  color: #ffffff;
  font-size: 18px;
  background-color: #619ef7;
  border-radius: 30px;
  font-weight: bold;
}

.nav .nav_ui .nav_li img {
  height: 50px;
  width: 200px;
  /* padding-top: 25px ; */
}
.nav .nav_ui .nav_li:nth-child(1) {
  width: 245px;
}

.nav_ui {
  a {
    text-decoration: none;
  }
}

.computer {
  // width: 100%;
  display: flex;
  /* /* padding-left: 43%; */
  padding-top: 12%;
}

.computer .banner_text {
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.computer .banner_text .text1 {
  color: #ffffff;
  font-size: 125px;
  font-weight: bold;
  padding-top: 50px;
}

.computer .banner_text .text2 {
  color: #ffffff;
  font-size: 65px;
  padding-top: 30px;
  img {
    width: 500px;
    height: auto;
    padding-top: 50px;
  }
}

.computer img {
  width: 950px;
  height: 685px;
}

.backtop {
  height: 100%;
  width: 100%;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 40px;
  color: #1989fa;
}
</style>
