
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
// import webgltest from "../view/WebglTest.vue";
import webgltest from "@/components/webgl/yaheen.vue";
// import Swiper from "swiper";
// import "swiper/swiper-bundle.min.css";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

SwiperCore.use([Autoplay]);
//创建vue示例
export default defineComponent({
  name: "AboutusC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    let list: any = [];
    let swiper: any = "";
    let autoplay: any = {
      delay: 1500,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    };
    return {
      we_status: 0,
      page: 1,
      displayNum: 1000,

      getStatus: false,
      list,
      pageNum: 1,
      total: 0,

      swiper,
      transformX: -375,
      slidesPerView: 4,
      loopedSlides: 4,

      autoplay,

      title: "",
      createtime: 0,
      html: "",

      DEBUG_SHOWABOUT: false, //原有显示关于我们
    };
  },
  watch: {
    visible(newVisible: boolean, oldVisible: boolean) {
      if (newVisible) {
        this.autoplay = oldVisible;
        this.swiper.disable();
      } else {
        this.swiper.enable();
        this.autoplay = {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        };
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    if (this.DEBUG_SHOWABOUT) {
      var swiper = new Swiper("#aboutsSwiper", {
        width: 1500,
        slidesPerView: this.slidesPerView,
        loopedSlides: this.loopedSlides,
        loop: false,
        on: {
          touchMove: (swiper: any) => {
            var TR = (this.page * this.displayNum - this.loopedSlides) * this.transformX;
            var translate = swiper.translate;
            if (translate < TR) {
              this.nextPage();
            }
          },
          touchEnd: () => {
            this.swiper.updateSlides();
          },
        },
      });
      this.swiper = swiper;
    }
  },
  methods: {
    on_we_status(sun: number) {
      this.we_status = sun;
      if (this.DEBUG_SHOWABOUT) {
        if (sun == 1) {
          this.swiper.updateSlides();
        }
      }
    },
    /**
     * swiper加载完成后的回调函数
     */
    onSwiper(swiper: any) {
      this.swiper = swiper;
    },
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.getStatus) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        } else {
          console.log("no next page~");
        }
      }
    },
    /**
     * 获取关于我们列表
     */
    getList() {
      this.getStatus = true;
      this.proxy.$request.aboutsList({
        data: { page: this.page, displayNum: this.displayNum },
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            // this.swiper.updateSlides();
            this.getStatus = false;
          }
        },
        error: (error: any) => {
          this.getStatus = false;
          console.log(error);
        },
      });
    },
    /**
     * 关闭窗口
     */
    closeInfo() {
      this.visible = false;
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.aboutsInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(result.data.createtime * 1000);
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    "v-article-info": articleInfo,
    "v-webgltest": webgltest,
  },
});
