
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);
//创建vue示例
export default defineComponent({
  name: "FervourC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    let list: any = [];
    let swiper: any = "";
    let listIndex: any = 0;
    return {
      page: 1,
      displayNum: 3,
      getStatus: false,
      list: list,
      listIndex: listIndex,
      pageNum: 1,
      total: 0,

      swiper: swiper,
      transformX: -1416 + 1 / 3,
      loopedSlides: 1,

      title: "",
      createtime: 0,
      html: "",
    };
  },
  created() {
    // setTimeout(() => {
          this.listIndex = 0;
    this.getList();
    // }, 2000);
  },
  mounted() {},
  methods: {
    onSwiper(swiper: any) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.listIndex = (this.swiper.translate / this.transformX).toFixed();
      var TR =
        (this.page * this.displayNum - this.loopedSlides - 1) * this.transformX;
      this.changeLast();
      if (TR >= this.swiper.translate) {
        this.nextPage();
      }
    },
    /**
     * 改变最后一张图片的位置
     */
    changeLast() {
      let listNum = this.list.length;
      let lastId = `swiper-slide-id-${listNum - 1}`;
      let last = document.getElementById(lastId);
      // 判断当前展示数据是否为最后一条
      // 是 最后一个展示框向左偏移-270px
      // 是 最后一个展示框向不偏移
      if (this.listIndex == listNum - 1) {
        if (last) {
          if (this.listIndex == 0) {
            last.style.left = "-140px";
          } else {
            last.style.left = "-270px";
          }
        }
      } else {
        if (last) {
          last.style.left = "0px";
        }
      }
    },
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.getStatus) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        } else {
          console.log("no next page~");
        }
      }
    },
    /**
     * 获取活动列表
     */
    getList() {
      this.getStatus = true;
      this.proxy.$request.activityList({
        data: { page: this.page, displayNum: this.displayNum },
        async: true,
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            setTimeout(() => {
              this.changeLast();
            }, 500);
            this.getStatus = false;
          }
        },
        error: (error: any) => {
          this.getStatus = false;
          console.log(error);
        },
      });
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.activityInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(
              result.data.createtime * 1000
            );
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    "v-article-info": articleInfo,
  },
});
