<template>
  <el-dialog
    :model-value="visible"
    custom-class="dia_he"
    width="75%"
    top="5vh"
    destroy-on-close
    center
    :before-close="handleClose"
  >
    <div class="article-info-content">
      <div class="title">
        {{ title }}
      </div>
      <div class="time">
        {{ createtime }}
      </div>
      <div v-html="html"></div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "articleInfo",
  setup(props, { emit }) {
    const { proxy }: any = getCurrentInstance();
    const handleClose = () => {
      emit("update:visible", false);
    };

    return {
      proxy,
      handleClose,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {},
  props: {
    title: String,
    createtime: String,
    html: String,
    visible: Boolean,
  },
});
</script>
<style lang="scss" scoped>
.dia_he{
  height: 90vh;
}
.article-info-content {
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  overflow: auto;
  img {
    height: unset;
  }
  .title {
    text-align: center;
    line-height: 40px;
    color: #090909;
    font-size: 24px;
  }
  .time {
    text-align: center;
    line-height: 40px;
  }
}
</style>
