<template>
  <div class="rigth">
    <div class="rigth_title">
      <p>YAHEEN</p>
      <p>解决方案</p>
      <p>THINK</p>
    </div>
    <div class="rigth_list">
      <ul
        class="infinite-list content"
        v-infinite-scroll="nextPage"
        infinite-scroll-disabled="disabled"
        style="overflow: auto; height: 550px"
      >
        <li
          v-for="(item, index) in list"
          :key="item.id"
          class="rigth_list_li"
          @click="getInfo(item.id)"
        >
          <template v-if="index == 0">
            <div class="rigth_top">
              <div class="top_main">
                <div class="main_img">
                  <img :src="item.thumbimage" alt="" />
                </div>
                <div class="main_text">
                  <div class="text_title">
                    <p class="title_txt">{{ item.title }}</p>
                    <img class="txt_icon" src="../../assets/top.webp" alt="" />
                  </div>
                  <div class="text_border"></div>
                  <p class="text_txt">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <img class="li_img" src="../../assets/li.webp" alt="" />
            <div class="li_text">
              <div class="text_main">
                <p class="main_p">{{ item.title }}</p>
                <span class="main_time">{{
                  proxy.$time.getYmdHis(item.createtime * 1000)
                }}</span>
              </div>
              <div class="text_border"></div>
            </div>
          </template>
        </li>
        <p class="font_loa" v-if="loading">加载中...</p>
        <p class="font_loa" v-if="noMore">没有更多了</p>
      </ul>
    </div>
  </div>
  <!-- 弹出层-文章详情 -->
  <v-article-info
    v-model:title="title"
    v-model:createtime="createtime"
    v-model:html="html"
    v-model:visible="visible"
  />
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
//创建vue示例
export default defineComponent({
  name: "ActionRight",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 10,

      list: list,
      pageNum: 1,
      total: 0,
      loading: false,
      noMore: false,

      title: "",
      createtime: 0,
      html: "",
    };
  },
  computed: {
    disabled(): boolean {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.loading) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        }
      }
    },
    /**
     * 获取思考列表
     */
    getList() {
      this.disabled = true;
      this.loading = true;

      this.proxy.$request.thinkList({
        data: { page: this.page, displayNum: this.displayNum },
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            this.loading = false;
            if (this.list.length == this.total) {
              this.noMore = true;
            }
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.thinkInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(result.data.createtime * 1000);
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    "v-article-info": articleInfo,
  },
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.rigth {
  width: 680px;
  height: 680px;
  border: #eeeeee solid 1px;
  .rigth_title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px auto 0;
    border-bottom: 2px solid #e6e6e6;
    p:nth-child(1) {
      width: 220px;
      color: #f0f0f0;
      font-size: 26px;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
    p:nth-child(2) {
      width: 120px;
      font-size: 30px;
      color: #191919;
      height: 70px;
      line-height: 70px;

      text-align: center;
    }
    p:nth-child(3) {
      width: 220px;
      color: #f0f0f0;
      font-size: 26px;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
  }
  .rigth_list {
    ul {
      list-style-type: none;
      width: 90%;
      margin: 20px auto;
      li {
        width: 100%;
        margin: auto;
        display: flex;
        // height: 48px;
      }
      li:nth-child(1) {
        background-color: #ffffff;
        font-weight: unset;
      }
      .font_loa{
        color: #c1c1c1;
        padding-top: 10px;
      }
    }
  }
}

.rigth_list_li {
  cursor: pointer;
  &:hover {
    // background-color: rgb(255, 250, 240);
    color: #f4af26;
    // .li_img {
    //   display: none;
    // }
    // .li_text {
    //   display: none;
    // }
    // .rigth_top {
    //   display: flex;
    // }
  }

  .li_img {
    width: 10px;
    height: 10px;
    margin: 5px;
    margin-top: 20px;
    // margin-top: 20px;
  }
  .li_text {
    width: 98%;
    height: 48px;
    // width: 100%;
    display: flex;
    flex-direction: column;
    .text_main {
      height: 48px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main_p {
        width: 70%;
        text-align: left;
        height: 48px;
        font-size: 14px;
        color: #353535;
        line-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
            .main_p:hover {
                  color: #f4af26;
            }
      .main_time {
        height: 48px;
        font-size: 14px;
        color: #c7c7c7;
        line-height: 48px;
      }
    }
    .text_border {
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed #ebebeb !important;
    }
  }

  .rigth_top {
    width: 100%;
    height: auto;
    // display: none;
    // flex-direction: column;
    .top_main {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .main_img {
        width: 175px;
        height: 130px;
        margin: 15px;
        margin-left: 0;
        img {
          width: 175px;
          height: 130px;
        }
      }
      .main_text {
        margin: 15px;
        display: flex;
        flex-direction: column;
            flex: 1;
        .text_title {
          width: 100%;
          display: flex;
          height: 25px;
          .title_txt {
            width: 80%;
            height: 30px;
            font-size: 18px;
            height: 25px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .txt_icon {
            width: 20px;
            height: 15px;
            padding-top: 5px;
            padding-left: 10px;
          }
        }
        .text_border {
          width: 100%;
          height: 1px;
          background-color: #e5e5e5;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .text_txt {
          width: 100%;
          height: 75px;
          line-height: 25px;
          font-size: 14px;
          color: #656565;
          text-align: left;
        }
      }
    }
  }
}
</style>
