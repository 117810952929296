
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "ActionLeftTop",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 10,
      list: list,
      pageNum: 1,
      total: 0,
      loading: false,
      noMore: false,
      status: {
        wait: "未开始",
        doing: "进行中",
        suspended: "已挂起",
        done: "已完成",
        maintain: "维护中",
      },
    };
  },
  computed: {
    disabled(): boolean {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.loading) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        }
      }
    },
    /**
     * 雅恒/获取项目
     */
    getList() {
      this.disabled = true;
      this.loading = true;
      this.proxy.$request.blockProject({
        data: { page: this.page, displayNum: this.displayNum },
        method: "get",
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                if(result.data.list.task.all != 0){
                var element = list[index];
                }
                this.list.push(element);
                
              }
            } else {
              this.list = result.data.list;
              console.log(this.list)
              // for (let index = 0; index < result.data.list.length; index++) {
              //   console.log(result.data.list[index].task.all)
              //   if(result.data.list.task.all != 0){
              //   this.list.push(result.data.list[index].task.all); 
              //   }
              // }
            }
          }
          this.loading = false;
          if (this.list.length == this.total) {
            this.noMore = true;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    },
  },
  components: {},
});
