import { render } from "./fervour.vue?vue&type=template&id=1f01eecb&scoped=true"
import script from "./fervour.vue?vue&type=script&lang=ts"
export * from "./fervour.vue?vue&type=script&lang=ts"

import "./fervour.vue?vue&type=style&index=0&id=1f01eecb&lang=scss&scoped=true"
import "./fervour.vue?vue&type=style&index=1&lang=scss"
import "./fervour.vue?vue&type=style&index=2&lang=scss"
script.render = render
script.__scopeId = "data-v-1f01eecb"

export default script