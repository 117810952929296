
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
//创建vue示例
export default defineComponent({
  name: "ActionRight",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 10,

      list: list,
      pageNum: 1,
      total: 0,
      loading: false,
      noMore: false,

      title: "",
      createtime: 0,
      html: "",
    };
  },
  computed: {
    disabled(): boolean {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.loading) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        }
      }
    },
    /**
     * 获取思考列表
     */
    getList() {
      this.disabled = true;
      this.loading = true;

      this.proxy.$request.thinkList({
        data: { page: this.page, displayNum: this.displayNum },
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            this.loading = false;
            if (this.list.length == this.total) {
              this.noMore = true;
            }
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.thinkInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(result.data.createtime * 1000);
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    "v-article-info": articleInfo,
  },
});
