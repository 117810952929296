<template>
  <div class="lab">
    <ul class="title">
      <li>
        <div class="main_li">
          <p>项目</p>
          <p>结束日期</p>
          <p>状态</p>
          <p>进度</p>
        </div>
      </li>
    </ul>
    <ul
      class="infinite-list content"
      v-infinite-scroll="nextPage"
      infinite-scroll-disabled="disabled"
      style="overflow: auto; height: 240px"
    >
      <li  v-for="item in list" :key="item.key" >
        <div class="main_li">
          <p>{{ item.name }}</p>
          <p>{{ item.end }}</p>
          <p>{{ status[item.status] }}</p>
          <p v-if="item.task.all != 0">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="Math.trunc((item.task.done / item.task.all) * 100)"
            ></el-progress>
          </p>
          <p v-else>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="0"
            ></el-progress>
          </p>
        </div>
      </li>
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
    </ul>
  </div>
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "ActionLeftTop",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 10,
      list: list,
      pageNum: 1,
      total: 0,
      loading: false,
      noMore: false,
      status: {
        wait: "未开始",
        doing: "进行中",
        suspended: "已挂起",
        done: "已完成",
        maintain: "维护中",
      },
    };
  },
  computed: {
    disabled(): boolean {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.loading) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        }
      }
    },
    /**
     * 雅恒/获取项目
     */
    getList() {
      this.disabled = true;
      this.loading = true;
      this.proxy.$request.blockProject({
        data: { page: this.page, displayNum: this.displayNum },
        method: "get",
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                if(result.data.list.task.all != 0){
                var element = list[index];
                }
                this.list.push(element);
                
              }
            } else {
              this.list = result.data.list;
              console.log(this.list)
              // for (let index = 0; index < result.data.list.length; index++) {
              //   console.log(result.data.list[index].task.all)
              //   if(result.data.list.task.all != 0){
              //   this.list.push(result.data.list[index].task.all); 
              //   }
              // }
            }
          }
          this.loading = false;
          if (this.list.length == this.total) {
            this.noMore = true;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    },
  },
  components: {},
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.lab {
  width: 100%;
  height: auto;
  overflow:hidden;
  
  .title {
    width: 100%;
    height: auto;
    display: flex;
    margin: auto;
    flex-direction: column;

    li:nth-child(1) {
      background-color: #e8e8e8;
      font-weight: bold;
    }
    li:nth-child(3),
    li:nth-child(5),
    li:nth-child(7) {
      background-color: #fafafa;
    }

    li {
      width: 100%;
      margin: auto;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main_li {
        width: 90%;
        // margin: auto;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
            margin-right: 50px;
            margin-left: 33px;
      }
      p {
        width: 25%;
        text-align: center;
      }
    }
    li:hover {
      background-color: #d6d6ff;
      border: 1px solid rgb(235, 235, 235);
      box-sizing: border-box;
    }
  }

  .content {
    width: 100%;
    height: auto;
    margin: auto;
    flex-direction: column;
    li:nth-child(2n + 1) {
      background-color: #fafafa;
      font-weight: unset;
    }
    li {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main_li {
        width: 90%;
        margin: auto;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        width: 25%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: pre-line;
      }
      p:nth-child(1) {
        text-align: left;
      }
    }
    li:hover {
      background-color: #e7e7e7;
      border: 1px solid rgb(235, 235, 235);
      box-sizing: border-box;
    }
  }
}
</style>
