
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
import actionLeftTop from "@/components/action/left/top.vue";
import actionLeftBottom from "@/components/action/left/bottom.vue";
//创建vue示例
export default defineComponent({
  name: "ActionLeft",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 2,
      list: list,
      pageNum: 1,
      total: 0,
    };
  },
  created() {},
  methods: {},
  components: {
    "v-action-left-top": actionLeftTop,
    "v-action-left-bottom": actionLeftBottom,
  },
});
