
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
import actionLeft from "@/components/action/left.vue";
import actionRight from "@/components/action/right.vue";
//创建vue示例
export default defineComponent({
  name: "ActionC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    "v-action-left": actionLeft,
    "v-action-right": actionRight,
  },
});
