
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "articleInfo",
  setup(props, { emit }) {
    const { proxy }: any = getCurrentInstance();
    const handleClose = () => {
      emit("update:visible", false);
    };

    return {
      proxy,
      handleClose,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {},
  props: {
    title: String,
    createtime: String,
    html: String,
    visible: Boolean,
  },
});
