<template>
  <div class="action" id="action">
    <div class="action_main">
      <div class="main_title">
        <p>YAHEEN</p>
        <p>行动</p>
        <p>WORK</p>
      </div>
      <div class="mains">
        <v-action-left></v-action-left>
        <v-action-right></v-action-right>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
import actionLeft from "@/components/action/left.vue";
import actionRight from "@/components/action/right.vue";
//创建vue示例
export default defineComponent({
  name: "ActionC",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    "v-action-left": actionLeft,
    "v-action-right": actionRight,
  },
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.action {
  width: 100%;
  height: 880px;
  margin: auto;
  .action_main {
    width: 100%;
    height: 880px;
    background-color: #ffffff;
    margin: auto;
    .main_title {
      width: 30%;
      display: flex;
      margin: 20px auto 0;
      p:nth-child(1) {
        width: 220px;
        color: #f0f0f0;
        font-size: 36px;
        height: 100px;
        line-height: 100px;
      }
      p:nth-child(2) {
        width: 120px;
        font-size: 42px;
        color: #191919;
        height: 100px;
        line-height: 100px;
      }
      p:nth-child(3) {
        width: 220px;
        color: #f0f0f0;
        font-size: 36px;
        height: 100px;
        line-height: 100px;
      }
    }
  }
  .mains {
    width: 1450px;
    display: flex;
    margin: 20px auto 0;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
