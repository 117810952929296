
import aboutus from "@/components/aboutus.vue";
import fervour from "@/components/fervour.vue";
import action from "@/components/action.vue";
import footer from "@/components/footer.vue";
import VueLazyload from 'vue-lazyload'

// import imgDefault from '@/assets/images/200.webp'
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
//创建vue示例
export default defineComponent({
  name: "Index",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  created() {
    this.proxy.$comm.title("首页");
  },
  mounted(){
  },
  methods: {
    roll(sun: number) {
      var timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        document.documentElement.scrollTop = document.body.scrollTop = sun;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    /**
     * 回到顶部功能实现过程：
     * 1. 获取页面当前距离顶部的滚动距离（虽然IE不常用了，但还是需要考虑一下兼容性的）
     * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
     * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
     * 4. 最后记得在移动到顶部时，清除定时器
     */
    backtop() {
      var timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        // this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
  components: {
    "v-aboutus": aboutus,
    "v-fervour": fervour,
    "v-action": action,
    "v-footer": footer,
  },
});
