<template>
  <div
    class="swiper-container"
    id="actionLeftBottomSwiper"
    style="width: 580px; height: 300px; margin: 0 auto"
  >
    <div class="box swiper-wrapper">
      <div class="box_left swiper-slide" v-for="item in list" :key="item.id">
        <div class="left_img" @click="getInfo(item.id)">
          <img :src="item.thumbimage" alt="" />
          <p>
            <span>{{ item.title }}</span>
          </p>
        </div>
        <p class="left_p">
          <span>{{ item.description }}</span>
        </p>
      </div>
    </div>
  </div>
  <!-- 弹出层-文章详情 -->
  <v-article-info
    v-model:title="title"
    v-model:createtime="createtime"
    v-model:html="html"
    v-model:visible="visible"
  />
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance, ref } from "vue";
import articleInfo from "@/components/articleInfo.vue";
import Swiper from "swiper";
import "swiper/swiper-bundle.min.css";
//创建vue示例
export default defineComponent({
  name: "ActionLeftBottom",
  setup() {
    const { proxy }: any = getCurrentInstance();
    const visible = ref(false);
    return {
      visible,
      proxy,
    };
  },
  data() {
    var { list }: any = [];
    var { swiper }: any = "";
    return {
      page: 1,
      displayNum: 3,

      getStatus: false,
      list: list,
      pageNum: 1,
      total: 0,

      swiper: swiper,
      transformX: -275,
      slidesPerView: 2,
      loopedSlides: 2,

      title: "",
      createtime: 0,
      html: "",
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    var swiper = new Swiper("#actionLeftBottomSwiper", {
      width: 580,
      slidesPerView: this.slidesPerView,
      loopedSlides: this.loopedSlides,
      loop: false,
      on: {
        touchMove: (swiper) => {
          var TR = (this.page * this.displayNum - this.loopedSlides) * this.transformX;
          var translate = swiper.translate;
          if (translate < TR) {
            this.nextPage();
          }
        },
        touchEnd: () => {
          this.swiper.updateSlides();
        },
      },
    });
    this.swiper = swiper;
  },
  methods: {
    /**
     * 获取下一页数据
     */
    nextPage() {
      if (!this.getStatus) {
        if (this.pageNum > this.page) {
          this.page += 1;
          this.getList();
        } else {
          console.log("no next page~");
        }
      }
    },
    /**
     * 获取项目文章列表
     */
    getList() {
      this.getStatus = true;
      this.proxy.$request.projectList({
        data: { page: this.page, displayNum: this.displayNum },
        async: true,
        success: (result: any) => {
          if (result.code == 1) {
            this.total = result.data.total;
            this.pageNum = Math.ceil(result.data.total / this.displayNum);
            if (this.page != 1) {
              let list = result.data.list;
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                this.list.push(element);
              }
            } else {
              this.list = result.data.list;
            }
            this.swiper.updateSlides();
            this.getStatus = false;
          }
        },
        error: (error: any) => {
          this.getStatus = false;
          console.log(error);
        },
      });
    },
    /**
     * 获取详情
     */
    getInfo(id: number) {
      this.proxy.$request.projectInfo({
        data: { id: id },
        success: (result: any) => {
          if (result.result) {
            this.title = result.data.title;
            this.createtime = this.proxy.$time.getYmdHis(result.data.createtime * 1000);
            this.html = result.data.maincontent;
            this.visible = true;
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    },
  },
  components: {
    "v-article-info": articleInfo,
  },
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.box {
  width: 680px;
  height: 680px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box_left {
    width: 290px;
    .left_img {
      width: 270px;
      height: 150px;
      img {
        width: 270px;
        height: 150px;
        cursor: pointer;
      }
      p {
        width: 270px;
        height: 30px;
        background-color: #18181a8c;
        position: relative;
        left: 0px;
        top: -35px;
        color: #ffff;
        z-index: 5;
        span {
          text-align: left;
          padding-left: 15px;
          line-height: 30px;
          overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        }
      }
    }
    .left_p {
      width: 270px;
      color: rgb(0, 0, 0);
      z-index: 5;
      padding-top: 15px;
      padding-left: 5px;

      span {
        // padding-left: 5px;
        // height: 30px;
        line-height: 25px;
        color: #6b6b6b;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
