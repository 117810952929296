<template>
  <div class="left">
    <div class="left_title">
      <p>YAHEEN</p>
      <p>承诺</p>
      <p>PROJECT</p>
    </div>
    <v-action-left-top></v-action-left-top>
    <v-action-left-bottom></v-action-left-bottom>
  </div>
</template>

<script lang="ts">
//引入vue实例
import { defineComponent, getCurrentInstance } from "vue";
import actionLeftTop from "@/components/action/left/top.vue";
import actionLeftBottom from "@/components/action/left/bottom.vue";
//创建vue示例
export default defineComponent({
  name: "ActionLeft",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
    };
  },
  data() {
    var { list }: any = [];
    return {
      page: 1,
      displayNum: 2,
      list: list,
      pageNum: 1,
      total: 0,
    };
  },
  created() {},
  methods: {},
  components: {
    "v-action-left-top": actionLeftTop,
    "v-action-left-bottom": actionLeftBottom,
  },
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.left {
  width: 680px;
  height: 680px;
  border: #eeeeee solid 1px;
  .left_title {
    width: 70%;
    display: flex;
    margin: 0px auto 0;
    // border-bottom: 2px solid #e6e6e6;
    p:nth-child(1) {
      width: 220px;
      color: #f0f0f0;
      font-size: 26px;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
    p:nth-child(2) {
      width: 120px;
      font-size: 30px;
      color: #191919;
      height: 70px;
      line-height: 70px;

      text-align: center;
    }
    p:nth-child(3) {
      width: 220px;
      color: #f0f0f0;
      font-size: 26px;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
  }
  .lab {
    width: 100%;
    height: auto;
    ul {
      width: 100%;
      height: auto;
      display: flex;
      margin: auto;
      flex-direction: column;
      li:nth-child(1) {
        background-color: #e8e8e8;
        font-weight: bold;
      }
      li:nth-child(3),
      li:nth-child(5),
      li:nth-child(7) {
        background-color: #fafafa;
      }
      li {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 25%;
          text-align: center;
        }
      }
      li:hover {
        background-color: #d6d6ff;
        border: 1px solid rgb(235, 235, 235);
        box-sizing: border-box;
      }
    }
  }
  .box {
    width: 90%;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_left {
      .left_img {
        width: 270px;
        height: 150px;
        img {
          width: 270px;
          height: 150px;
        }
        p {
          width: 270px;
          height: 30px;
          background-color: #18181a8c;
          position: relative;
          left: 0px;
          top: -35px;
          color: #ffff;
          z-index: 5;
          span {
            padding-left: 15px;
          }
        }
      }
      .left_p {
        width: 270px;
        color: rgb(0, 0, 0);
        z-index: 5;
        padding-top: 15px;
        padding-left: 5px;
        span {
          // padding-left: 5px;
          // height: 30px;
          line-height: 25px;
          color: #6b6b6b;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
